import apiRequest from '../../api/apiRequest'
import { message } from 'antd'
import {
  POST_USER_LIST_SUCCESS,
  GET_USER_LISTS_SUCCESS,
  DELETE_USER_LIST_SUCCESS,
  GET_USER_LIST_BY_ID_SUCCESS,
  UPDATE_USER_LIST_SUCCESS,
} from '../Constants'
import { loadUser } from './authActions'

export const postUserList = (userListData) => async (dispatch) => {
  await apiRequest({ method: 'post', url: 'v1/userList', data: userListData })
    .then((response) => {
      dispatch({
        type: POST_USER_LIST_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const deleteUserList = (id) => async (dispatch) => {
  await apiRequest({ method: 'delete', url: `v1/userList/${id}` })
    .then(() => {
      dispatch({
        type: DELETE_USER_LIST_SUCCESS,
        payload: id,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const getUserListByIdApi = async (slug) => {
  const response = await apiRequest({
    method: 'get',
    url: `v1/userList/${slug}`,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })

  return response
}

export const getUserListByIdApiPublic = async (slug) => {
  const response = await apiRequest({
    method: 'get',
    url: `v1/userList/user/${slug}`,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })

  return response
}

export const getUserListById = (slug) => async (dispatch) => {
  await apiRequest({ method: 'get', url: `v1/userList/${slug}` })
    .then((response) => {
      dispatch({
        type: GET_USER_LIST_BY_ID_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const getUserLists = () => async (dispatch) => {
  await apiRequest({ method: 'get', url: 'v1/userList' })
    .then((response) => {
      dispatch({
        type: GET_USER_LISTS_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const updateUserList = (id, updateData) => async (dispatch) => {
  await apiRequest({
    method: 'patch',
    url: `v1/userList/${id}`,
    data: updateData,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_USER_LIST_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}
