// PRODUCTS
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_About = 'GET_About'
export const SET_SEARCH_PRODUCT = 'SET_SEARCH_PRODUCT'
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
export const SET_SEARCH_NULL = 'SET_SEARCH_NULL'
export const UPGRADE_PRODUCTS = 'UPGRADE_PRODUCTS'

// Register Types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

// Login Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

// Logout Types
export const LOGOUT = 'LOGOUT'

// Authentication Types
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'

// update
export const USER_UPDATE = 'USER_UPDATE'
export const GET_PRODUCTS_ANALTICS = 'GET_PRODUCTS_ANALTICS'
export const SET_PAGE = 'SET_PAGE'
export const SET_SIGNUP_MODAL = 'SET_SIGNUP_MODAL'
export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL'
export const GET_PRODUCTS_SAVED = 'GET_PRODUCTS_SAVED'
export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS'
export const SET_POPULAR_BRANDS = 'SET_POPULAR_BRANDS'
export const HIDE_MERCHANT = 'HIDE_MERCHANT'
export const HIDE_PRODUCT = 'HIDE_PRODUCT'
export const SET_PRICE_FILTER = 'SET_PRICE_FILTER'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_POPULAR_PRODUCTS = 'SET_POPULAR_PRODUCTS'
export const SET_BRAND_PRODUCTS = 'SET_BRAND_PRODUCTS'
export const GET_BRANDS_SAVED = 'GET_BRANDS_SAVED'
export const POST_USER_LIST_SUCCESS = 'POST_USER_LIST_SUCCESS'
export const GET_USER_LISTS_SUCCESS = 'GET_USER_LISTS_SUCCESS'
export const GET_USER_LIST_BY_ID_SUCCESS = 'GET_USER_LIST_BY_ID_SUCCESS'
export const DELETE_USER_LIST_SUCCESS = 'DELETE_USER_LIST_SUCCESS'
export const UPDATE_USER_LIST_SUCCESS = 'UPDATE_USER_LIST_SUCCESS'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_ERROR = 'USER_LIST_ERROR'
export const SET_CURRENCY = 'SET_CURRENCY'
export const GET_BEST_PERFORMING_PRODUCTS = 'GET_BEST_PERFORMING_PRODUCTS'
export const GET_PRODUCTS_DASHBOARD = 'GET_PRODUCTS_DASHBOARD'
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const DELETE_PAYMENT_METHODS = 'DELETE_PAYMENT_METHODS'
export const CHANGE_DEFAULT_PAYMENT_METHODS = 'CHANGE_DEFAULT_PAYMENT_METHODS'
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS'
export const GET_BOOSTED_PRODUCTS = 'GET_BOOSTED_PRODUCTS'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const SET_POPULAR_STORES = 'SET_POPULAR_STORES'
export const SHOW_LOADER = 'SHOW_LOADER'
export const REMOVE_INVALID_PRODUCTS = 'REMOVE_INVALID_PRODUCTS'
export const ASSISTANT_CHAT = 'ASSISTANT_CHAT'
export const ASSISTANT_LOADING = 'ASSISTANT_LOADING'
export const ASSISTANT_MESSAGE_ADD = 'ASSISTANT_MESSAGE_ADD'
export const GET_COLLECTIONS = 'GET_COLLECTIONS'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CARTS = 'GET_CARTS'
export const GET_TAGS = 'GET_TAGS'
export const GET_ALERTS = 'GET_ALERTS'
export const GET_DASHBOARD_CHART = 'GET_DASHBOARD_CHART'
export const GET_PRODUCTS_DASHBOARD_VERIFIED = 'GET_PRODUCTS_DASHBOARD_VERIFIED'
export const GET_AVERAGE_CPC = 'GET_AVERAGE_CPC'
