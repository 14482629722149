import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import style from '../../style/merchant.module.css'
import Link from 'next/link'
const CookieConsentModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    // if (!localStorage.getItem('cookieAccepted')) setIsModalVisible(true)
  }, [])

  const handleAccept = () => {
    // Implement your logic to handle acceptance of cookies here
    // setIsModalVisible(false)
    // localStorage.setItem('cookieAccepted', true)
  }

  const handleCustomize = () => {
    // Implement your logic to handle customization of cookies here
    // setIsModalVisible(false)
  }

  return (
    <>
      <Modal visible={isModalVisible} footer={null} closable={false} centered>
        <h2 className='text-center'>
          we use cookies to improve your experience on agora
        </h2>
        <p className='text-center'>
          we use cookies to collect data for functionality, analytics, and
          personalized advertising.
        </p>
        <p className='text-center'>
          by clicking accept you agree to these cookies <br />
          <Link href='/cookies'>read more</Link>
        </p>
        <div className='d-flex flex-column'>
          <Button
            type='primary'
            onClick={handleAccept}
            className={style.editBtn}
            style={{ height: '50px', marginBottom: '1em' }}
          >
            accept
          </Button>

          <a className='text-center' href='mailto:support@searchagora.com'>
            contact us
          </a>
        </div>
      </Modal>
    </>
  )
}

export default CookieConsentModal
