import React, { useEffect } from 'react'
import axios from 'axios'
import { sendTwitterToken } from '../redux/action/authActions'
import { useDispatch } from 'react-redux'

function removeQueryParams() {
  if (window.history.pushState) {
    let url = new URL(window.location.href)
    let baseUrl = url.origin + url.pathname

    window.history.pushState({}, document.title, baseUrl)
  }
}

const TwitterOAuthRedirectHandler = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const oauth_token = new URLSearchParams(window.location.search).get(
      'oauth_token'
    )
    const oauth_verifier = new URLSearchParams(window.location.search).get(
      'oauth_verifier'
    )

    if (oauth_token && oauth_verifier) {
      // Exchange the code for an access token
      dispatch(sendTwitterToken(oauth_token, oauth_verifier))
      // window.location.href = '/'
    }
  }, [])

  return <div></div>
}

export default TwitterOAuthRedirectHandler
