import apiRequest from '../../api/apiRequest'
import { message, Modal } from 'antd'
import moment from 'moment'
import {
  GET_PRODUCTS_ANALTICS,
  GET_BEST_PERFORMING_PRODUCTS,
  GET_PRODUCTS_DASHBOARD,
  SET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHODS,
  CHANGE_DEFAULT_PAYMENT_METHODS,
  GET_PRODUCT_DETAILS,
  GET_BOOSTED_PRODUCTS,
  GET_TRANSACTIONS,
  GET_CUSTOMERS,
  GET_CARTS,
  GET_ORDERS,
  GET_TAGS,
  GET_ALERTS,
  GET_DASHBOARD_CHART,
  GET_PRODUCTS_DASHBOARD_VERIFIED,
  GET_AVERAGE_CPC,
} from '../Constants'
import { loadUser } from './authActions'

export const getDashboardAnalytics =
  (startDate = false, endDate = false, init = true) =>
  async (dispatch) => {
    if (!startDate) startDate = moment().subtract(4, 'months').format()
    if (!endDate) endDate = moment().format()
    await apiRequest({
      method: 'post',
      url: 'v1/analytics/dashboard-analytics',
      data: {
        start: startDate || moment().subtract(120, 'days').format(),
        end: endDate || moment().format(),
      },
    })
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_ANALTICS,
          payload: {
            ...response,
            startDate: moment(startDate),
            endDate: moment(endDate),
          },
        })
        if (init) {
          dispatch(getPaymentMethods())
          dispatch(getBoostedProducts())
          dispatch(getTags())
          dispatch(getAlerts())
          dispatch(getDashboardChart())
          dispatch(getBoostAverageCPC())
        }
      })
      .catch((error) => {
        console.log(error)

        return false
      })
    return
  }

export const getDashboardChart = (startDate, endDate) => {
  return function (dispatch) {
    apiRequest({
      method: 'post',
      url: 'v1/analytics/dashboard-analytics/chart',
      data: {
        start: startDate || moment().subtract(120, 'days').format(),
        end: endDate || moment().format(),
      },
    })
      .then((response) => {
        dispatch({
          type: GET_DASHBOARD_CHART,
          payload: { ...response },
        })
      })
      .catch((error) => {
        console.log(error)

        return false
      })
  }
}

// GET PRODUCTS
export const getProductsVerified = () => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: `v1/product/get-verified-products`,
  })
    .then((response) => {
      dispatch({
        type: GET_PRODUCTS_DASHBOARD_VERIFIED,
        payload: { ...response },
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
export const orderProductIndex = (id, data) => {
  apiRequest({
    method: 'POST',
    url: `v1/product-management/reorder/${id}`,
    data,
  })
    .then((response) => {})
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// GET PRODUCTS
export const getProducts =
  ({ page, query }) =>
  async (dispatch) => {
    await apiRequest({
      method: 'get',
      url: `v1/product?page=${page}&limit=20&query=${query}`,
    })
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_DASHBOARD,
          payload: { ...response, page },
        })

        dispatch(getProductsVerified())
      })
      .catch((error) => {
        // message.error(error)
        return false
      })
  }
// GET PRODUCTS
export const deleteProduct = (prodId) => async (dispatch) => {
  await apiRequest({
    method: 'DELETE',
    url: `v1/store-management/delete-product/${prodId}`,
  })
    .then((response) => {
      dispatch({
        type: GET_PRODUCTS_DASHBOARD,
        payload: { ...response, page },
      })
      dispatch(getDashboardAnalytics())
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const resetProductDetailPage = () => (dispatch) => {
  dispatch({
    type: GET_PRODUCT_DETAILS,
    payload: {},
  })
}

// GET PRODUCTS
export const getProductWithAnalytics = (id, start, end) => async (dispatch) => {
  dispatch(loadUser())
  await apiRequest({
    method: 'post',
    url: `v1/product/product-details/${id}`,
    data: {
      start,
      end,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PRODUCT_DETAILS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// GET PRODUCTS
export const deleteProductReview = (id, productId) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: `v1/review/delete/${id}`,
    data: {
      productId,
    },
  })
    .then((response) => {
      message.success('Review deleted successfully.')
      return true
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
export const getSubmissionDetails = async (id) => {
  return await apiRequest({
    method: 'get',
    url: `v1/store-management/submission/${id}`,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const getBestPerformingProducts = () => {
  return function (dispatch) {
    apiRequest({ method: 'get', url: 'v1/analytics/best-performing-products' })
      .then((response) => {
        dispatch({ type: GET_BEST_PERFORMING_PRODUCTS, payload: response })
      })
      .catch((error) => {
        console.log(error)

        return false
      })
  }
}

export const getBoostedProducts = () => {
  return function (dispatch) {
    apiRequest({ method: 'get', url: 'v1/analytics/boosted-products' })
      .then((response) => {
        dispatch({ type: GET_BOOSTED_PRODUCTS, payload: response })
      })
      .catch((error) => {
        console.log(error)

        return false
      })
  }
}

export const getBoostAverageCPC = () => {
  return function (dispatch) {
    apiRequest({ method: 'get', url: 'v1/product-management/average-boost' })
      .then((response) => {
        dispatch({ type: GET_AVERAGE_CPC, payload: response.averageCPC })
      })
      .catch((error) => {
        console.log(error)

        return false
      })
  }
}

// upgrade products
export const buySubscription = (data) => (dispatch) => {
  return apiRequest({ method: 'post', url: `v1/upgrade`, data })
    .then((response) => {
      dispatch(loadUser())
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// upgrade products
export const buyTrialSubscription = (data) => (dispatch) => {
  return apiRequest({ method: 'post', url: `v1/upgrade/trial`, data })
    .then((response) => {
      dispatch(loadUser())
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// upgrade products
export const upgradeSubscription = (data) => (dispatch) => {
  return apiRequest({ method: 'patch', url: `v1/upgrade`, data })
    .then((response) => {
      dispatch(loadUser())
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// upgrade products
export const cancelSubscription = (data) => (dispatch) => {
  return apiRequest({ method: 'post', url: `v1/upgrade/cancel`, data })
    .then((response) => {
      dispatch(loadUser())
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// upgrade products
export const downgradeSubscription = (data) => (dispatch) => {
  return apiRequest({ method: 'delete', url: `v1/upgrade`, data })
    .then((response) => {
      dispatch(loadUser())
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// upgrade products
export const getTransactions = (data) => (dispatch) => {
  return apiRequest({ method: 'get', url: `v1/upgrade/transactions`, data })
    .then((response) => {
      dispatch({ type: GET_TRANSACTIONS, payload: response })
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// upgrade products
export const verifyProducts = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'post',
    url: `v1/upgrade/verify-products`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      dispatch(getProducts({ page: 1, query: '' }))
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}
// upgrade products
export const unVerifyProducts = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'DELETE',
    url: `v1/upgrade/verify-products`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      dispatch(getProducts({ page: 1, query: '' }))
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}
// upgrade products
export const boostProduct = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'post',
    url: `v1/upgrade/boost-product`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      dispatch(getTransactions())
      dispatch(getProducts({ page: 1, query: '' }))
      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}
// upgrade products
export const unBoostProduct = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'DELETE',
    url: `v1/upgrade/boost-product`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      dispatch(getTransactions())
      dispatch(getProducts({ page: 1, query: '' }))

      return response
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}

export const setupPaymentMethod = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: 'v1/payment-method',
    data,
  })
    .then((response) => {
      dispatch(getPaymentMethods())
    })
    .catch((error) => {
      console.log(error)

      return false
    })
}
export const chargePaymentMethod = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: 'v1/payment-method/charge',
    data,
  })
    .then((response) => {})
    .catch((error) => {
      console.log(error)

      return false
    })
}

export const deletePaymentMethod = (data) => async (dispatch) => {
  await apiRequest({
    method: 'delete',
    url: `v1/payment-method/${data.paymentMethodId}`,
  })
    .then((response) => {
      Modal.success({
        title: 'Payment method delete',
        content: 'Payment method deleted successfully.',
      })
      dispatch({ type: DELETE_PAYMENT_METHODS, payload: data.paymentMethodId })
    })
    .catch((error) => {
      if (error.message) message.error(error.message)

      return false
    })
}
export const changePaymentMethod = (data) => async (dispatch) => {
  await apiRequest({
    method: 'patch',
    url: 'v1/payment-method',
    data,
  })
    .then((response) => {
      Modal.success({
        title: 'Default card change',
        content: 'Card marked as primary',
      })

      dispatch(getPaymentMethods())
    })
    .catch((error) => {
      // if (error.message) message.error(error.message)

      return false
    })
}

export const getPaymentMethods = () => (dispatch) => {
  apiRequest({ method: 'get', url: 'v1/payment-method' })
    .then((response) => {
      dispatch({ type: GET_PAYMENT_METHODS, payload: response })
    })
    .catch((error) => {
      console.log(error)

      return false
    })
}
export const getOrders = () => (dispatch) => {
  apiRequest({ method: 'get', url: 'v1/order/store-orders' })
    .then((response) => {
      dispatch({ type: GET_ORDERS, payload: response })
    })
    .catch((error) => {
      console.log(error)

      return false
    })
}
export const getCarts = () => (dispatch) => {
  apiRequest({ method: 'get', url: 'v1/order/store-carts' })
    .then((response) => {
      dispatch({ type: GET_CARTS, payload: response })
    })
    .catch((error) => {
      console.log(error)

      return false
    })
}

export const postStoreSettings = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'post',
    url: 'v1/store-management/settings',
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      return true
    })
    .catch((error) => {
      console.log(error)

      return false
    })

  return response
}
export const postStoreRename = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'post',
    url: 'v1/store-management/rename',
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      return true
    })
    .catch((error) => {
      console.log(error)

      return false
    })

  return response
}

export const getCustomers = () => (dispatch) => {
  apiRequest({ method: 'get', url: 'v1/order/store-customers' })
    .then((response) => {
      dispatch({ type: GET_CUSTOMERS, payload: response })
    })
    .catch((error) => {
      console.log(error)

      return false
    })
}

export const submitNewStoreForCrawl = (data, storeType) => async (dispatch) => {
  const response = await apiRequest({
    method: 'POST',
    url:
      storeType == 'wix'
        ? 'v1/store-management/crawl-wix-store'
        : 'v1/store-management/crawl-squarespace-store',
    data,
  })
    .then((response) => {
      return true
    })
    .catch((error) => {
      console.log(error)

      return false
    })
  return response
}

export const submitTags = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'POST',
    url: 'v1/alerts/tags',
    data,
  })
    .then((response) => {
      dispatch({
        type: GET_TAGS,
        payload: response.data.tags,
      })
      dispatch(getAlerts())
      return true
    })
    .catch((error) => {
      console.log(error)

      return false
    })
  return response
}

export const getTags = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'GET',
    url: 'v1/alerts/tags',
  })
    .then((response) => {
      dispatch({
        type: GET_TAGS,
        payload: response.data.tags,
      })
    })
    .catch((error) => {
      console.log(error)

      return false
    })
  return response
}

export const getAlerts = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'GET',
    url: 'v1/alerts',
  })
    .then((response) => {
      dispatch({
        type: GET_ALERTS,
        payload: response.data,
      })
      return true
    })
    .catch((error) => {
      console.log(error)

      return false
    })
  return response
}
