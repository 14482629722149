// init.js

if (typeof window !== 'undefined') {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const isRunningOnLocalhost =
    typeof window !== 'undefined' && window.location.hostname === 'localhost'

  if (!(isDevelopment && isRunningOnLocalhost)) {
    // Preserve the original console.log in case you need to enable it conditionally
    const originalConsoleLog = console.log

    // Override console methods you wish to disable
    const noop = () => {}
    console.log = noop
    console.warn = noop
    console.error = noop
    console.info = noop
    // Add any other console methods you want to disable

    // Optionally, you can create a mechanism to re-enable console logging if needed for debugging
    window.enableConsole = () => {
      console.log = originalConsoleLog
      console.warn = originalConsoleLog
      console.error = originalConsoleLog
      console.info = originalConsoleLog
      // Re-enable other methods as needed
    }
  }
}
