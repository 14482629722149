import apiRequest from '../../api/apiRequest'
import { message, Modal } from 'antd'
import {
  GET_About,
  SET_SEARCH_PRODUCT,
  SET_SELECTED_PRODUCT,
  SET_SEARCH_NULL,
  SET_SIGNUP_MODAL,
  SET_LOGIN_MODAL,
  GET_PRODUCTS_SAVED,
  SET_SEARCH_TERMS,
  SET_POPULAR_BRANDS,
  HIDE_PRODUCT,
  HIDE_MERCHANT,
  SET_COUNTRY,
  SET_POPULAR_PRODUCTS,
  SET_BRAND_PRODUCTS,
  GET_BRANDS_SAVED,
  POST_USER_LIST_SUCCESS,
  GET_USER_LISTS_SUCCESS,
  DELETE_USER_LIST_SUCCESS,
  GET_USER_LIST_BY_ID_SUCCESS,
  UPDATE_USER_LIST_SUCCESS,
  SET_CURRENCY,
  SET_POPULAR_STORES,
  REMOVE_INVALID_PRODUCTS,
  ASSISTANT_CHAT,
  ASSISTANT_LOADING,
  ASSISTANT_MESSAGE_ADD,
  GET_COLLECTIONS,
} from '../Constants'
import { loadUser } from './authActions'
import { getProducts } from './dashboardActions'
import { v4 as uuidv4 } from 'uuid'

function getRandomNumber() {
  // Math.random() returns a floating-point, pseudo-random number in the range 0 to less than 1
  // Multiply by 3 to get a number in the range 0 to less than 3
  // Use Math.floor() to round down to the nearest whole number
  // Add 1 to change the range from 0-2 to 1-3
  return Math.floor(Math.random() * 3) + 1
}

// GET PRODUCTS
export const getAutoCompleteResults = async (query) => {
  let results = await apiRequest({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_URL}v1/product/autocomplete?q=${query}`,
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return results
}

// GET PRODUCTS
export const getSavedBrands = () => async (dispatch) => {
  await apiRequest({ method: 'get', url: `v1/interactions/saved-brand` })
    .then((response) => {
      dispatch({
        type: GET_BRANDS_SAVED,
        payload: response.brands,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// GET PRODUCTS
export const getCollections = () => async (dispatch) => {
  await apiRequest({ method: 'get', url: `v1/collection` })
    .then((response) => {
      dispatch({
        type: GET_COLLECTIONS,
        payload: response.collections,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const getCollectionBySlug = (slug) => async (dispatch) => {
  const response = await apiRequest({
    method: 'get',
    url: `v1/collection/${slug}`,
  })
    .then((response) => {
      return response.collection
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}

export const getSearches = () => async (dispatch, getState) => {
  let { typesenseClient } = getState().dashboard

  let searchParameters = {
    per_page: 50,
    page: 1,
    use_cache: true,
    cache_ttl: 600,
    sort_by: 'popularity:desc',
    q: '*',
  }

  let results = await typesenseClient
    .collections('searches')
    .documents()
    .search(searchParameters)
  let response = results.hits.map((item) => ({
    ...item.document,
    _id: item.document.id,
  }))

  dispatch({
    type: SET_SEARCH_TERMS,
    payload: response,
  })

  // let completeResults = []
  // for (let i = 0; i < 5; i++) {
  //   let searchParametersTest = {
  //     per_page: 200,
  //     page: i + 1,
  //     use_cache: true,
  //     cache_ttl: 600,
  //     query_by: 'storeName',
  //     q: 'Georgia Crafted',
  //   }

  //   let resultsTest = await typesenseClient
  //     .collections('products')
  //     .documents()
  //     .search(searchParametersTest)
  //   resultsTest = resultsTest.hits.map((item) => ({
  //     ...item.document,
  //     _id: item.document.id,
  //   }))

  //   if (resultsTest.length > 0) completeResults.push(...resultsTest)
  // }
  // console.log(completeResults, 'Results are ')
}

export const getProductByCollection = () => async (dispatch, getState) => {
  let { typesenseClient } = getState().dashboard

  let searchParameters = {
    per_page: 50,
    page: 1,
    use_cache: true,
    cache_ttl: 600,
    sort_by: 'popularity:desc',
    q: '*',
  }

  let results = await typesenseClient
    .collections('searches')
    .documents()
    .search(searchParameters)
  let response = results.hits.map((item) => ({
    ...item.document,
    _id: item.document.id,
  }))

  dispatch({
    type: SET_SEARCH_TERMS,
    payload: response,
  })

  // let completeResults = []
  // for (let i = 0; i < 5; i++) {
  //   let searchParametersTest = {
  //     per_page: 200,
  //     page: i + 1,
  //     use_cache: true,
  //     cache_ttl: 600,
  //     query_by: 'storeName',
  //     q: 'Georgia Crafted',
  //   }

  //   let resultsTest = await typesenseClient
  //     .collections('products')
  //     .documents()
  //     .search(searchParametersTest)
  //   resultsTest = resultsTest.hits.map((item) => ({
  //     ...item.document,
  //     _id: item.document.id,
  //   }))

  //   if (resultsTest.length > 0) completeResults.push(...resultsTest)
  // }
  // console.log(completeResults, 'Results are ')
}

export const getPopularBrands = (productId) => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: `v1/analytics/most-popular-brands`,
  })
    .then((response) => {
      dispatch({
        type: SET_POPULAR_BRANDS,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
export const getPopularStores = (productId) => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: `v1/analytics/most-popular-stores`,
  })
    .then((response) => {
      dispatch({
        type: SET_POPULAR_STORES,
        payload: response,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const getProductsByBrand = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: `v1/analytics/brands?&page=${data.page}`,
    data: {
      query: data.query,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_BRAND_PRODUCTS,
        payload: { ...response, page: data.page },
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
export const getProductsByStore = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: `v1/analytics/store?page=${data.page}`,
    data: {
      query: data.query,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_BRAND_PRODUCTS,
        payload: { ...response, page: data.page },
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
export const setBrandProducts = (data) => (dispatch) => {
  dispatch({
    type: SET_BRAND_PRODUCTS,
    payload: data,
  })
}

export const getMostPopularProducts = (data) => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: `v1/analytics/most-popular-products?page=${data.page}`,
  })
    .then((response) => {
      dispatch({
        type: SET_POPULAR_PRODUCTS,
        payload: { ...response, page: data.page },
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const setPopularProducts = (data) => (dispatch) => {
  dispatch({
    type: SET_POPULAR_PRODUCTS,
    payload: data,
  })
}

// GET PRODUCTS
export const saveAProduct = (productId) => async (dispatch) => {
  await apiRequest({
    method: 'get',
    url: `v1/interactions/save-product/${productId}`,
  })
    .then((response) => {
      dispatch({
        type: GET_PRODUCTS_SAVED,
        payload: response.products,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// GET PRODUCTS
export const saveABrand = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: `v1/interactions/save-brand`,
    data,
  })
    .then((response) => {
      dispatch({
        type: GET_BRANDS_SAVED,
        payload: response.brands,
      })
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const hideProduct = (page) => (dispatch) => {
  dispatch({ type: HIDE_PRODUCT, payload: page })
}
export const hideMerchant = (page) => (dispatch) => {
  dispatch({ type: HIDE_MERCHANT, payload: page })
}

// CREATE PRODUCT
export const createProducts = (data) => {
  return function (dispatch) {
    apiRequest({
      method: 'post',
      url: 'v1/product-management',
      data,
    })
      .then((response) => {
        dispatch(getProducts({ page: 1, query: '' }))
        dispatch(loadUser())
        // message.success('Successfully created!')
      })
      .catch((error) => {
        // message.error(error)
        return false
      })
  }
}
// DELETE  PRODUCT
export const deleteProducts = (id) => {
  return function (dispatch) {
    apiRequest({ method: 'DELETE', url: `v1/product-management/${id}` })
      .then((response) => {
        dispatch(getProducts({ page: 1, query: '' }))
        dispatch(loadUser())
        message.success(response.message)
      })
      .catch((error) => {
        // message.error(error)
        return false
      })
  }
}
// UPDATE PRODUCT
export const updateProducts = (data) => async (dispatch) => {
  const form_data = new FormData()
  for (const key in data) {
    form_data.append(key, data[key])
  }

  const response = await apiRequest({
    method: 'PATCH',
    url: `v1/product-management/${data.id}`,
    data: form_data,
  })
    .then((response) => {
      dispatch(getProducts({ page: 1, query: '' }))
      dispatch(loadUser())
      message.success('Successfully Updated!')
      return true
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}

// UPDATE PRODUCT tags
export const updateProductTags = (data) => async (dispatch) => {
  const response = await apiRequest({
    method: 'PATCH',
    url: `v1/product/${data.id}/update-keywords`,
    data: { keywords: data.keywords },
  })
    .then((response) => {
      dispatch(getProducts({ page: 1, query: '' }))
      dispatch(loadUser())
      message.success('Successfully Updated!')
      return true
    })
    .catch((error) => {
      // message.error(error)
      return false
    })

  return response
}

// GET ABOUT
export const getAbout = () => {
  return function (dispatch) {
    apiRequest({ method: 'get', url: 'v1/aboutcount/' })
      .then((response) => {
        dispatch({ type: GET_About, payload: response.Counts })
      })
      .catch((error) => {
        // message.error(error)
        return false
      })
  }
}
// ADD REVIEW AND RATING
export const addReviewRating = (data, id) => async (dispatch) => {
  await apiRequest({ method: 'post', url: `v1/review/${id}`, data })
    .then((response) => {
      // dispatch(getProducts())
      dispatch(increaseClick(id))
      message.success('Review added!')
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}
// ADD TEAM
export const addTeam = (data) => async (dispatch) => {
  let response = await apiRequest({
    method: 'post',
    url: `v1/users/addTeamMember`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      Modal.success({
        title: 'Team member',
        content: 'Team member successfully added.',
      })
      return true
    })
    .catch((error) => {
      message.error(error.message)
      return false
    })

  return response
}
export const deleteTeam = (id) => async (dispatch) => {
  await apiRequest({ method: 'DELETE', url: `v1/users/${id}` })
    .then(async (response) => {
      await dispatch(loadUser())
      message.success('User removed from team')
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

// SEND INVITE
export const sendInvite = (data) => async (dispatch) => {
  await apiRequest({
    method: 'post',
    url: `v1/users/sendInvitationToTeam`,
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      Modal.info({ title: 'Member invitation', content: 'Invitation sent.' })
    })
    .catch((error) => {
      Modal.info({ title: 'Member invitation', content: error.message })
      return false
    })
}

export const assistantChat = (data) => async (dispatch) => {
  dispatch({ type: ASSISTANT_LOADING, payload: true })

  await apiRequest({
    method: 'post',
    url: `v1/assistant`,
    data,
  })
    .then((response) => {
      dispatch({ type: ASSISTANT_LOADING, payload: false })
      dispatch({
        type: ASSISTANT_MESSAGE_ADD,
        payload: {
          sender: 'athena',
          text: response.msg,
          id: uuidv4(),
          products: response.products,
        },
      })
    })
    .catch((error) => {
      dispatch({ type: ASSISTANT_LOADING, payload: false })

      return false
    })
}
export const setAssistantChat = (data) => async (dispatch) => {
  dispatch({ type: ASSISTANT_CHAT, payload: data })
}

export const increaseClickThrough = (id) => {
  apiRequest({ method: 'get', url: `v1/analytics/increaseClick/${id}` })
    .then((response) => {})
    .catch((error) => {
      // message.error(error)
    })
}

export const addQueryToProduct = (id, query) => {
  if (query)
    apiRequest({
      method: 'post',
      url: `v1/analytics/addQuery/${id}`,
      data: { query },
    })
      .then((response) => {})
      .catch((error) => {
        // message.error(error)
      })
}
export const recordProductShare = (id) => {
  apiRequest({
    method: 'GET',
    url: `v1/analytics/record-share/${id}`,
  })
    .then((response) => {})
    .catch((error) => {
      // message.error(error)
    })
}
// INCREASE CLICKS
export const increaseClick = (id) => (dispatch) => {
  if (id) {
  } else {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: {
        avrageRating: '',
        product: {
          description: '',
          impressions: '',
          clicks: '',
          id: '',
          image: '',
          name: '',
          ratings: [],
        },
      },
    })
  }
}

export const setSelectedProduct = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRODUCT,
    payload: data
      ? data
      : {
          avrageRating: '',
          product: {
            description: '',
            impressions: '',
            clicks: '',
            id: '',
            image: '',
            name: '',
            ratings: [],
          },
        },
  })
}

export const setSearchProducts = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_PRODUCT,
    payload: data,
  })
}
export const removeInvalidProducts = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_INVALID_PRODUCTS,
    payload: data,
  })
}

// SEARCH~
export const search = (data) => async (dispatch) => {
  let searchPath = `?q=${data.query}&page=${data.page}&limit=20`
  let seedExists = localStorage.getItem('seed')

  if (data.minPrice) {
    searchPath += `&minPrice=${data.minPrice}`
  }
  if (data.maxPrice) {
    searchPath += `&maxPrice=${data.maxPrice}`
  }
  if (data.shipToCountry) {
    searchPath += `&shipToCountry=${data.shipToCountry}`
  }

  if (data.saved) {
    searchPath += `&saved=${data.saved}`
  }
  if (data.price) {
    searchPath += `&price=${data.price}`
  }
  if (data.type) {
    searchPath += `&type=${data.type}`
  }

  if (seedExists) {
    searchPath += `&seed=${seedExists}`
  }
  // if (data.invalidIds) {
  //   searchPath += `&invalidIds=${data.invalidIds.join(',')}`
  // }

  await apiRequest({
    method: 'get',
    url: `v1/product/search/v2${searchPath}`,
  })
    .then((response) => {
      localStorage.setItem('seed', response.seed)

      dispatch({
        type: SET_SEARCH_PRODUCT,
        payload: { ...response, page: data.page },
      })
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}

// set search product
export const setSearchProduct = () => (dispatch) => {
  dispatch({ type: SET_SEARCH_NULL, payload: null })
}

// set search product
export const setCountry = (payload) => (dispatch) => {
  dispatch({ type: SET_COUNTRY, payload: payload })
}
// set search product
export const setCurrency = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENCY, payload: payload })
}

// upgrade products
export const crawlPage = (data) => {
  const response = apiRequest({
    method: 'post',
    url: `v1/store-management/crawl-store`,
    data,
  })
    .then((response) => {
      message.info('we are scraping the store, thank you')
    })
    .catch((error) => {
      // message.error(error[0].message)
      return false
    })

  return response
}
// upgrade products
export const reCrawlProducts = () => async (dispatch) => {
  const response = await apiRequest({
    method: 'get',
    url: `v1/store-management/recrawl-store`,
  })
    .then((response) => {
      dispatch(getProducts({ page: 1, query: '' }))
      dispatch(loadUser())

      return true
    })
    .catch((error) => {
      console.log(error)
      // message.error(error[0].message)
      return false
    })

  return response
}
export const sendFeedback = (data) => (dispatch) => {
  apiRequest({ method: 'post', url: `v1/feedback`, data })
    .then((response) => {
      // message.success('Thank you for your feedback')
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const support = (data) => (dispatch) => {
  apiRequest({ method: 'post', url: `v1/support`, data })
    .then((response) => {
      message.success("Thank you! We'll reach out within 24 hours via email")
    })
    .catch((error) => {
      // message.error(error)
      return false
    })
}

export const setSignUpModal = (data) => (dispatch) => {
  dispatch({ type: SET_SIGNUP_MODAL, payload: data })
}
export const setLoginModal = (data) => (dispatch) => {
  dispatch({ type: SET_LOGIN_MODAL, payload: data })
}

export const postUserList = (userListData) => async (dispatch) => {
  await apiRequest({ method: 'post', url: 'v1/user-list', data: userListData })
    .then((response) => {
      dispatch({
        type: POST_USER_LIST_SUCCESS,
        payload: response.data,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const deleteUserList = (id) => async (dispatch) => {
  await apiRequest({ method: 'delete', url: `v1/user-list/${id}` })
    .then(() => {
      dispatch({
        type: DELETE_USER_LIST_SUCCESS,
        payload: id,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const getUserListById = (id) => async (dispatch) => {
  await apiRequest({ method: 'get', url: `v1/user-list/${id}` })
    .then((response) => {
      dispatch({
        type: GET_USER_LIST_BY_ID_SUCCESS,
        payload: response.data,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const getUserLists = () => async (dispatch) => {
  await apiRequest({ method: 'get', url: 'v1/user-list' })
    .then((response) => {
      dispatch({
        type: GET_USER_LISTS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}

export const updateUserList = (id, updateData) => async (dispatch) => {
  await apiRequest({
    method: 'patch',
    url: `v1/user-list/${id}`,
    data: updateData,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_USER_LIST_SUCCESS,
        payload: response.data,
      })
    })
    .catch((error) => {
      // message.error(error.message)
      return false
    })
}
