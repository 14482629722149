// This default export is required in a new `pages/_app.js` file.

import 'antd/dist/antd.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'react-lazy-load-image-component/src/effects/blur.css'

import '../App.css'
import Head from 'next/head'
import { Provider } from 'react-redux'
import createStore from '../redux/Store' // Adjust the path as needed
import { NavigationHistoryProvider } from '../context/navigationProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import TwitterOAuthRedirectHandler from '../components/TwitterOAuthRedirectHandler'
import UseCountry from '../components/useCountry'
import '../helpers/logInit'
import { useEffect } from 'react'
import CookieConsentModal from '../components/modals/CookieModal'

const store = createStore()

export default function App({ Component, pageProps }) {
  useEffect(() => {
    function generateSeed() {
      // Generate a seed between 1 and 100
      let num = Math.floor(Math.random() * 100) + 1
      localStorage.setItem('seed', num)
    }
    generateSeed()
  }, [])

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta name='theme-color' content='#000000' />
        <link rel='icon' type='image' href='/favicon.png' />
        <link rel='icon' type='image/x-icon' href='/favicon.png' />

        <meta
          name='description'
          content='Agora is the search engine for e-commerce products. We help merchants sell more by giving users an easy way to search, browse, and buy e-commerce products across the internet.'
        />
        <meta property='og:title' content='Agora Merchant Dashboard' />
        <meta
          property='og:description'
          content='Agora is the search engine for e-commerce products. We help merchants sell more by giving users an easy way to search, browse, and buy e-commerce products across the internet.'
        />
        <meta property='og:type' content='video.movie' />
        <meta property='og:url' content='https://www.searchagora.com/' />

        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Righteous&display=swap'
          rel='stylesheet'
        />

        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        <meta name='twitter:title' content='Agora Merchant Dashboard' />
        <meta
          name='twitter:description'
          content='Agora is the search engine for e-commerce products. We help merchants sell more by giving users an easy way to search, browse, and buy e-commerce products across the internet.'
        />
        <meta name='twitter:image' content='/socialShare.png' />
        <meta name='og:image' content='/socialShare.png' />
        <meta
          name='twitter:card'
          content='Agora | The Search Engine for E-Commerce'
        />
        <link rel='apple-touch-icon' href='/favicon.png' />
        <link rel='manifest' href='/manifest.json' />
        <title>Agora Merchant Dashboard</title>

        <script
          type='module'
          src='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
        ></script>
        <link
          rel='stylesheet'
          href='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'
        />

        <script
          type='module'
          src='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
        ></script>
        <link
          rel='stylesheet'
          href='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'
        />
        {/* Google Tag Manager */}

        {/* <script src='https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js'></script> */}

        <script
          dangerouslySetInnerHTML={{
            __html: `
    document.addEventListener('DOMContentLoaded', function() {
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NNVR3VPL');
    });
    `,
          }}
        />

        {/* Dynamically add Share API Polyfill */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
    document.addEventListener('DOMContentLoaded', function() {
      var script = document.createElement('script');
      script.src = 'https://unpkg.com/share-api-polyfill/dist/share-min.js';
      script.async = true;
      document.body.appendChild(script);
    });
    `,
          }}
        />
      </Head>
      <NavigationHistoryProvider>
        <GoogleOAuthProvider
          clientId={
            '344493403039-jhv4nj68vl6m639vp6b42ksoeqqv6lgs.apps.googleusercontent.com'
          }
        >
          <Provider store={store}>
            <Component {...pageProps} />
            <CookieConsentModal />
            <UseCountry />
            <TwitterOAuthRedirectHandler />
            {/* <SpeedInsights /> */}

            {/* <Analytics /> */}
          </Provider>
        </GoogleOAuthProvider>
      </NavigationHistoryProvider>
    </>
  )
}
