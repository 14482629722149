import {
  GET_PRODUCTS,
  GET_About,
  SET_SEARCH_PRODUCT,
  SET_SELECTED_PRODUCT,
  SET_SEARCH_NULL,
  GET_PRODUCTS_ANALTICS,
  SET_PAGE,
  SET_SIGNUP_MODAL,
  SET_LOGIN_MODAL,
  GET_PRODUCTS_SAVED,
  SET_SEARCH_TERMS,
  SET_POPULAR_BRANDS,
  SET_POPULAR_STORES,
  HIDE_PRODUCT,
  HIDE_MERCHANT,
  SET_COUNTRY,
  SET_POPULAR_PRODUCTS,
  SET_BRAND_PRODUCTS,
  GET_BRANDS_SAVED,
  POST_USER_LIST_SUCCESS,
  GET_USER_LISTS_SUCCESS,
  DELETE_USER_LIST_SUCCESS,
  GET_USER_LIST_BY_ID_SUCCESS,
  SET_CURRENCY,
  REMOVE_INVALID_PRODUCTS,
  ASSISTANT_CHAT,
  ASSISTANT_LOADING,
  ASSISTANT_MESSAGE_ADD,
  GET_COLLECTIONS,
} from '../Constants'
import _ from 'lodash'

const initState = {
  hasMore: false,
  signUpModal: false,
  loginModal: false,
  searchProduct: [],
  popularProducts: [],
  brandProducts: [],
  collections: [],
  assistantMessages: [
    {
      id: 1,
      text: `Hi! I'm Athena, your AI-Powered Agora Assistant. What can I help you find? Describe it in as much detail as possible.`,
      sender: 'athena',
    },
  ],
  assistantLoading: false,
  savedProduct: [],
  savedBrand: [],
  searchCount: 0,
  popularCount: 0,
  popularPage: 0,
  brandCount: 0,
  searchHasMore: false,
  popularHasMore: false,
  brandHasMore: false,
  searchTerms: [],
  popularBrands: [],
  popularStores: [],
  selectedProduct: {
    avrageRating: '',
    product: {
      description: '',
      impressions: '',
      clicks: '',
      id: '',
      image: '',
      name: '',
      ratings: [],
    },
  },
  counts: {
    Clicks: 0,
    Merchants: 0,
    Products: 0,
    Ratings: 0,
    Reviews: 0,
    Searches: 0,
    Stores: 0,
    Brands: 0,
    saves: 0,
    OpenCount: 0,
  },
  country: '',
  currency: '',
}

const appReducer = function (state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products:
          payload.page == 1
            ? payload.Products
            : [...state.products, ...payload.Products],
        hasMore: payload.hasMore,
      }

    case SET_COUNTRY:
      let countryComponent = {
        short_name: payload.short_name,
        long_name: payload.long_name,
      }

      localStorage.setItem('country', JSON.stringify(countryComponent))

      return {
        ...state,
        country: {
          long_name: payload.long_name.toLowerCase(),
          short_name: payload.short_name.toLowerCase(),
        },
      }
    case SET_CURRENCY:
      localStorage.setItem('currency', payload)

      return {
        ...state,
        currency: payload,
      }
    case ASSISTANT_CHAT:
      return {
        ...state,
        assistantMessages: payload,
      }
    case GET_COLLECTIONS:
      return {
        ...state,
        collections: payload,
      }
    case ASSISTANT_CHAT:
      return {
        ...state,
        assistantMessages: payload,
      }
    case ASSISTANT_MESSAGE_ADD:
      return {
        ...state,
        assistantMessages: [...state.assistantMessages, payload],
      }
    case ASSISTANT_LOADING:
      return {
        ...state,
        assistantLoading: payload,
      }
    case SET_SEARCH_TERMS:
      return {
        ...state,
        searchTerms: payload,
      }
    case SET_POPULAR_BRANDS:
      return {
        ...state,
        popularBrands: payload.brands
          ? payload.brands.filter(
              (item) => item._id !== '' && item._id !== 'undefined'
            )
          : [],
      }

    case SET_POPULAR_STORES:
      return {
        ...state,
        popularStores: payload.stores
          ? payload.stores.filter(
              (item) => item._id !== '' && item._id !== 'undefined'
            )
          : [],
      }

    case GET_PRODUCTS_SAVED:
      return {
        ...state,
        savedProduct: payload,
      }
    case GET_BRANDS_SAVED:
      return {
        ...state,
        savedBrand: payload,
      }

    case HIDE_MERCHANT:
      return {
        ...state,
        searchProduct: state.searchProduct.filter(
          (item) => item.brand !== payload.brand
        ),
      }
    case HIDE_PRODUCT:
      return {
        ...state,
        searchProduct: state.searchProduct.filter(
          (item) => item._id !== payload._id
        ),
      }
    case REMOVE_INVALID_PRODUCTS:
      return {
        ...state,
        searchProduct: payload,
      }
    case GET_About:
      return {
        ...state,
        counts: payload,
      }
    case SET_SEARCH_PRODUCT:
      return {
        ...state,
        searchProduct:
          payload.page == 1
            ? payload.Products
            : [...state.searchProduct, ...payload.Products],
        searchCount: payload.count,
        searchHasMore: payload.hasMore,
      }
    case SET_POPULAR_PRODUCTS:
      let tempProd =
        payload.page == 1
          ? payload.Products
          : [...state.popularProducts, ...payload.Products]

      tempProd = tempProd.filter(
        (item) =>
          item._id !== '65ba0e049ab2899d0dc3730b' &&
          item._id !== '65b854bbe5bc3f21c6fb7b61'
      )

      tempProd = _.uniqBy(tempProd, 'id')

      return {
        ...state,
        popularProducts: tempProd,
        popularCount: payload.count,
        popularHasMore: payload.hasMore,
        popularPage: payload.page,
      }
    case SET_BRAND_PRODUCTS:
      return {
        ...state,
        brandProducts:
          payload.page == 1
            ? payload.Products
            : [...state.brandProducts, ...payload.Products],
        brandCount: payload.count,
        brandHasMore: payload.hasMore,
      }
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      }
    case SET_SIGNUP_MODAL:
      return {
        ...state,
        signUpModal: payload,
      }
    case SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: payload,
      }
    case SET_SEARCH_NULL:
      return {
        ...state,
        searchProduct: _.uniqBy(payload, '_id'),
      }
    default:
      return { ...state }
  }
}

export default appReducer
