import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCountry, setCurrency } from '../redux/action/appActions'
import currencyMap from '../data/currencyMap.json'
import apiRequest from '../api/apiRequest'
const DetectCountryGoogleAPI = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let country = localStorage.getItem('country')
      ? JSON.parse(localStorage.getItem('country'))
      : ''

    if (country && country.long_name !== '') {
      dispatch(setCountry(country))
    }

    let currency = localStorage.getItem('currency')
      ? localStorage.getItem('currency')
      : ''

    if (currency) {
      dispatch(setCurrency(currency))
    }

    if (country && currency) console.log('do nothing')
    else
      apiRequest({
        method: 'GET',
        url: '/detect-country',
      }).then((res) => {
        if (res.country !== '') {
          let countryComponent = {
            short_name: res.countryCode,
            long_name: res.country,
          }
          dispatch(setCountry(countryComponent))
          const foundCurrency = currencyMap.find(
            (item) =>
              item.country.toLowerCase() ==
              countryComponent.long_name.toLowerCase()
          )

          if (foundCurrency) {
            dispatch(setCurrency(foundCurrency.currency_code))

            localStorage.setItem('country', JSON.stringify(countryComponent))
            localStorage.setItem('currency', foundCurrency.currency_code)
          }
        }
      })
  }, [dispatch])

  return <></>
}

export default DetectCountryGoogleAPI
