import {
  POST_USER_LIST_SUCCESS,
  GET_USER_LISTS_SUCCESS,
  DELETE_USER_LIST_SUCCESS,
  GET_USER_LIST_BY_ID_SUCCESS,
  UPDATE_USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_ERROR,
} from '../Constants'
import _ from 'lodash'
const initialState = {
  userLists: [],
  userList: null,
  loading: false,
  error: null,
}
const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_LIST_SUCCESS:
      return {
        ...state,
        userLists: [...state.userLists, action.payload],
        loading: false,
      }

    case GET_USER_LISTS_SUCCESS:
      return {
        ...state,
        userLists: action.payload,
        loading: false,
      }

    case GET_USER_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      }

    case DELETE_USER_LIST_SUCCESS:
      return {
        ...state,
        userLists: state.userLists.filter(
          (list) => list._id !== action.payload
        ),
        loading: false,
      }

    case UPDATE_USER_LIST_SUCCESS:
      return {
        ...state,
        userLists: state.userLists.map((list) =>
          list._id === action.payload._id ? action.payload : list
        ),
        loading: false,
      }

    case USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case USER_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default userListReducer
