import apiRequest from '../../api/apiRequest'
import { message } from 'antd'
import setAuthToken from '../../helpers/setAuthToken'
import { getSavedBrands } from './appActions'
import { getUserLists } from './listActions'
import {
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_UPDATE,
  SHOW_LOADER,
} from '../Constants'

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token)
  }

  apiRequest({
    method: 'get',
    url: 'v1/users/me',
  })
    .then((response) => {
      dispatch({
        type: USER_LOADED,
        payload: response.user,
      })
    })
    .catch((error) => {
      dispatch({
        type: AUTH_ERROR,
      })
      console.log(error)
    })
}

//Register user
export const register = (user) => async (dispatch) => {
  let response = await apiRequest({
    method: 'post',
    url: 'v1/users/signup',
    data: user,
  })
    .then((response) => {
      // dispatch({ type: REGISTER_SUCCESS, payload: response })
      return true
    })
    .catch((error) => {
      dispatch({ type: REGISTER_FAILURE })
      message.error(error[0].message)
      return false
    })
  return response
}

//Register user
export const sendSlackNotification = async (msg, channelId) => {
  let response = await apiRequest({
    method: 'post',
    url: 'v1/users/notification',
    data: { msg, channelId },
  })
    .then((response) => {
      // dispatch({ type: REGISTER_SUCCESS, payload: response })
      return true
    })
    .catch((error) => {
      return false
    })
  return response
}

//Register user
export const verifyAccount = (otp) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true })
  let response = await apiRequest({
    method: 'get',
    url: `v1/users/verifyOtp?otp=${otp}`,
  })
    .then(async (response) => {
      localStorage.setItem('token', response.token)
      setAuthToken(response.token)

      await dispatch(loadUser())

      return true
    })
    .catch((error) => {
      dispatch({ type: LOGIN_FAIL })
      dispatch({ type: SHOW_LOADER, payload: false })

      message.error(error[0].message)
      return false
    })
  return response
}

//Register user
export const updateSlug = (data) => async (dispatch) => {
  let response = await apiRequest({
    method: 'post',
    url: 'v1/users/update-slug',
    data,
  })
    .then((response) => {
      dispatch(loadUser())
      return true
    })
    .catch((error) => {
      message.error(error[0].message)
      return false
    })
  return response
}

//Login user
export const login = (data) => async (dispatch) => {
  let response = await apiRequest({
    method: 'post',
    url: 'v1/users/login',
    data,
  })
    .then((response) => {
      dispatch({ type: LOGIN_SUCCESS, payload: response })
      dispatch(loadUser())
      return true
    })
    .catch((error) => {
      console.log(error)
      dispatch({ type: LOGIN_FAIL })
      message.error(error[0].message)
      return false
    })
  return response
}

//Login user
export const loginGoogle = (token) => async (dispatch) => {
  let response = await apiRequest({
    url: 'v1/users/google-login',
    method: 'POST',
    data: {
      token,
    },
    headers: { withCredentials: true },
  })
    .then((response) => {
      dispatch({ type: LOGIN_SUCCESS, payload: response })
      dispatch(loadUser())
      return true
    })
    .catch((error) => {
      console.log(error)
      dispatch({ type: LOGIN_FAIL })
      message.error(error[0].message)
      return false
    })

  return response
}

export const getTwitterToken = async () => {
  let response = await apiRequest({
    url: 'v1/users/twitter-token',
    method: 'GET',
  })
    .then((response) => {
      window.location.href = response.url
    })
    .catch((error) => {
      console.log(error[0].message)
      message.error(error[0].message)
      return false
    })

  return response
}

export const sendTwitterToken =
  (oauth_token, oauth_verifier) => async (dispatch) => {
    let response = await apiRequest({
      url: `v1/users/twitter-token/exchange?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`,
      method: 'GET',
    })
      .then((response) => {
        dispatch({ type: LOGIN_SUCCESS, payload: response })
        dispatch(loadUser())
        window.location.href = '/'
        return true
      })
      .catch((error) => {
        console.log(error[0].message)
        message.error(error[0].message)
        return false
      })

    return response
  }

export const forgetPassword = (data) => {
  return function (dispatch) {
    return apiRequest({
      method: 'post',
      url: 'v1/users/forget',
      data,
    })
      .then((response) => {
        message.success('Password reset link sent to your email')
        return response
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAIL })
        message.error('Not found')
        return error[0]
      })
  }
}

export const verifyForgetPassword = (data) => {
  return apiRequest({
    method: 'post',
    url: '/users/forgot/verify',
    data,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      message.error(error)
    })
}

export const changePassword = (data) => {
  return function (dispatch) {
    apiRequest({
      method: 'post',
      url: '/users/forgot/change',
      data,
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAIL })
        message.error(error)
      })
  }
}

// update user
export const update = (data) => {
  return function (dispatch) {
    apiRequest({ method: 'PATCH', url: 'v1/users/update/profile', data })
      .then((response) => {
        dispatch({ type: USER_UPDATE, payload: response.user })
        message.success('User Successfully updated!')
      })
      .catch((error) => {
        message.error(error[0].message)
        return false
      })
  }
}

// change password

// export const changePassword = (data) => {
//   apiRequest({ method: "post", url: "/users/change-password", data })
//     .then((res) => {
//       message.success("Password Changed Successfully!");
//     })
//     .catch((error) => {
//       message.error("Failed to change password");
//       console.log(error);
//     });
// };

export const logout = () => {
  return function (dispatch) {
    apiRequest({ method: 'get', url: 'v1/users/logout' })
      .then((response) => {
        dispatch({ type: LOGOUT, payload: response })
        localStorage.removeItem('token')

        message.success('Logged out')
        window.location = '/'
      })
      .catch((error) => {
        message.error(error)
        return false
      })
  }
}
