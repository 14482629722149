import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers/Index'
// trigger deploymeny
const isClient = () => typeof window !== 'undefined'

const middleware = [thunk]

const isDevelopment = process.env.NODE_ENV === 'development'

if (isClient()) {
  const isRunningOnLocalhost = window.location.hostname === 'localhost'

  // Only add logger middleware in development mode and when running on localhost
  if (isDevelopment || isRunningOnLocalhost) {
    middleware.push(logger)
  }
}

const store = () =>
  createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)))

export default store
