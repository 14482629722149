import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_UPDATE,
  SHOW_LOADER,
} from '../Constants'
import setAuthToken from '../../helpers/setAuthToken'
import apiRequest from '../../api/apiRequest'
import planData from '../../data/plans.json'
const initState = {
  user: null,
  // token:  localStorage.getItem('token'),
  token: '',
  isAuthenticated: null,
  loading: true,
}

const authenticationReducer = function (state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case USER_LOADED:
      let tempUser = {}

      if (payload.type !== 'customer' && payload.parentId)
        tempUser = {
          ...payload,
          subscribedPlan: payload.parentId.subscribedPlan,
          subscriptionId: payload.parentId.subscriptionId,
          verifiedProducts: payload.parentId.verifiedProducts,
        }
      else tempUser = payload

      if (tempUser.subscribedPlan && tempUser.subscriptionId) {
        tempUser['verifiedCount'] = parseInt(
          planData.plans
            .find((item) => item.name === tempUser.subscribedPlan)
            .features[8].replace('--', 0)
        )
        tempUser['keywordLimit'] = 5

        tempUser['verifyDiscount'] = planData.plans.find(
          (item) => item.name === tempUser.subscribedPlan
        ).features[10]

        tempUser['teamSize'] = planData.plans.find(
          (item) => item.name === tempUser.subscribedPlan
        ).features[11]

        if (tempUser.verifiedCount)
          tempUser.remainingVerifiedCount =
            tempUser['verifiedCount'] - tempUser.verifiedProducts.length
        else tempUser.remainingVerifiedCount = 0
      } else {
        tempUser['teamSize'] = 1
        tempUser['verifiedCount'] = 0
        tempUser['keywordLimit'] = 0
        tempUser['verifyDiscount'] = '%0'
      }

      return {
        ...state,
        isAuthenticated: true,
        user: tempUser,
        loading: false,
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token)
      setAuthToken(payload.token)
      const tempProduct = localStorage.getItem('tempSaveProduct')
      if (tempProduct) {
        apiRequest({
          method: 'get',
          url: `v1/interactions/save-product/${tempProduct}`,
        }).catch((error) => {
          // message.error(error)
          return false
        })
        localStorage.removeItem('tempSaveProduct')
      }
      return {
        user: payload.user,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      }
    case USER_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          firstname: payload.firstname,
          lastname: payload.lastname,
          email: payload.email,
        },
      }
    case REGISTER_FAILURE:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      }
    case SHOW_LOADER:
      return {
        ...state,
        loading: payload,
      }
    default:
      return { ...state }
  }
}

export default authenticationReducer
