import React, { createContext, useContext, useState } from 'react'

const NavigationHistoryContext = createContext()

export const NavigationHistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([])

  return (
    <NavigationHistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </NavigationHistoryContext.Provider>
  )
}

export const useNavigationHistory = () => useContext(NavigationHistoryContext)
