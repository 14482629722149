import { combineReducers } from 'redux'
import appReducer from './appReducers'
import authReducers from './authReducers'
import userListReducer from './listReducers'
import dashboardReducer from './dashboardReducers'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducers,
  lists: userListReducer,
  dashboard: dashboardReducer,
})

export default rootReducer
